/* eslint-env browser */
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Container } from 'react-bootstrap';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Content from '../components/Content';
import Menu from '../components/Menu';
import specialtiesContent from '../consts/specialties-content';

const pageStyles = {
  paddingTop: '96px',
  minHeight: '100vh',
  borderBottom: '14px solid #D5C65D',
  fontFamily: 'Open Sans, -apple-system, Roboto, sans-serif, serif',
  background: '#fbfbfb',
};

const Specialties = ({ name }) => {
  useEffect(() => {
    if (!specialtiesContent[name]) navigate('/');
  });

  const openWhatsAppChat = () => {
    document.querySelector('.whatsapp-open-button').click();
  };

  const [hasClosetWhatsAppChat, setHasClosetWhatsAppChat] = useState(false);
  const handleCloseWhatsAppChat = () => {
    window.sessionStorage.setItem('hasClosedWhatsAppChat', 'true');
  };
  useEffect(() => {
    const item = window.sessionStorage.getItem('hasClosedWhatsAppChat') || 'false';
    setHasClosetWhatsAppChat(JSON.parse(item));
  }, []);

  if (!specialtiesContent[name]) {
    return <div />;
  }
  return (
    <main style={pageStyles}>
      <title>Serviços</title>
      <FloatingWhatsApp
        accountName="Rose da Pilares"
        avatar="/blue_logo.jpg"
        phoneNumber="5511960750276"
        statusMessage="Online"
        placeholder="Escreva uma mensagem"
        chatMessage="Olá! Sou a Rose assistente virtual, como posso ajudar?"
        notification={!hasClosetWhatsAppChat}
        notificationSoundSrc="/notification.mp3"
        notificationSound
        notificationDelay={5}
        notificationLoop={1}
        buttonClassName="whatsapp-open-button"
        onNotification={openWhatsAppChat}
        onClose={handleCloseWhatsAppChat}
      />
      <Menu />
      <Container>
        <Content
          title={specialtiesContent[name].title}
          subtitle={specialtiesContent[name].subtitle}
          description={specialtiesContent[name].description}
          images={specialtiesContent[name].images}
          text={specialtiesContent[name].text}
        />
      </Container>
    </main>
  );
};

export default Specialties;
